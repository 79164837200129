import React from 'react'
import { Box, Typography, Button, Modal, Select, MenuItem, FormControl, InputLabel, Stack, TextField, IconButton, FormHelperText } from '@mui/material'
import { useEffect, useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { createUserSessions } from '@/utils/UserInfomations'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useToasterContext } from '@/utils/ToasterContext';

import validate from 'validate.js'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import ScrollContainer from 'react-indiana-drag-scroll'
import dayjs from 'dayjs';

dayjs.extend(require("dayjs/plugin/customParseFormat"));
dayjs.extend(require("dayjs/plugin/isBetween"));

const ModalWaitingList = ({ open, onClose, session, subscriptions, reload }) => {
    
    const { users, capacity, trainer, class_sessions } = session
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const { token, user } = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [confirm, setConfirm] = useState(false);
    const [shared, setShared] = useState([]);
    const [isOnlyShared, setIsOnlyShared] = useState(0);
    const [personalSelect, setPersonalSelect] = useState(false);
    const [formError, setFormError] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(false);
    const [selectedClassType, setSelectedClassType] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const class_types = session?.class?.class_types;
        if(class_types){
            let classTypeInfo = class_types ? JSON.parse(class_types) : null;
            if(Array.isArray(classTypeInfo) && classTypeInfo.length && session?.class_types){
                let selectedClass = classTypeInfo.find((val) => val?.title == session?.class_types);
                setSelectedClassType(selectedClass);
            }
        }
    }, [session]);

    async function handleBookSession(event) {
        event.preventDefault();
        const formData = {
            subscription_id: selectedSubscription?.id,
            shared: shared.filter((val) => val != ''),
            is_only_shared: isOnlyShared ? 1 : 0,
            session_id: session?.id
        }
        const error = validate(formData, createSessionConstraint);
        if (!error) {
            try {
                setIsLoading(true);
                startProgress();
                const response = await createUserSessions(token, formData);
                if (response && !response.error) {
                    const { session_id } = response;
                    showMessage('success', 'Data created successfully!');
                    if (session_id) {
                        navigate("/account/sessions/" + session_id);
                    }
                } else {
                    showMessage('error', response?.data?.message || t('An error occurred, please try again later'));
                    if(reload){
                        reload();
                    }
                }
            } catch (error) {
                showMessage('error', t('An error occurred, please try later'));
                if(reload){
                    reload();
                }
            } finally {
                setIsLoading(false);
                completeProgress();
            }
        }
        setFormError(error);
    }

    let createSessionConstraint = {};

    if(session?.is_free_session == 0){
        createSessionConstraint = {
            subscription_id: {
                presence: true,
            }
        }
    }

    function handleChangeSubscription(e){
        setShared([]);
        const selectedSubscription = subscriptions.find((val) => val.id == e.target.value);
        setIsOnlyShared(0);
        setSelectedSubscription(selectedSubscription);
    }

    function handleChangeShared(value, index) {
        const updated = shared.map((_v, _i) => index == _i ? value : _v);
        setShared(updated);
    }

    function handleDeleteShared(index) {
        const updated = shared.filter((_v, _i) => _i != index);
        setShared(updated);
    }

    function handleAddShared() {
        setShared((shared) => [...shared, ""]);
    }

    function handleSelectPersonalBook(value){
        setIsOnlyShared(value);
        setShared([]);
        setPersonalSelect(true);
    }

    let filterSubscription = Array.isArray(subscriptions) && subscriptions?.length ? 
        subscriptions.filter((val) => {
            const is_private = val?.membership?.is_private;
            if(is_private == 1) return false;
            const validateDate = val?.trigger_active == 1 ? true: dayjs(session?.start_time).isBetween(dayjs(val.start_date).startOf('day'), dayjs(val.end_date).endOf('day'));
            const validateClass = val.membership.is_all_class === 1 || val.membership.academic_classes.some((cls) => cls.id === session.class.id);
            const validateUsage = val.max_sessions === -1 || val.class_session_user_count < val.max_sessions;
            if (!validateDate || !validateClass || !validateUsage) {
                return false
            }
            return true;
        }) : [];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 540,
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const start = dayjs(session.start_time);
    const end = dayjs(session.end_time);
    // Tính khoảng thời gian giữa hai thời điểm trong phút
    const durationInMinutes = end.diff(start, 'minute');

    // Tính toán trước số lượng `shared` hợp lệ để tránh lặp lại
    const validSharedLength = shared.filter((val) => val !== '').length;

    // Điều kiện tính toán cho `disabledSharedButton`
    const sharedCountAdjustment = isOnlyShared ? 0 : 1;
    const validateUsers = Array.isArray(class_sessions) && class_sessions?.length ? class_sessions.filter((val) => val?.status == 'active') : [];

    const hasReachedCapacity = (capacity - validateUsers?.length) <= (shared?.length + sharedCountAdjustment);
    const hasUnlimitedSessions = selectedSubscription.max_sessions == "-1";
    const hasAvailableSessions = (selectedSubscription?.class_session_user_count + shared?.length + sharedCountAdjustment) <= ( selectedSubscription?.max_sessions - 1 );

    console.log('hasReachedCapacity', hasReachedCapacity);
    const disabledSharedButton = hasReachedCapacity
        ? true
        : hasUnlimitedSessions
            ? false
            : !hasAvailableSessions;

    // Tính `usageCounter` dựa trên giá trị `validSharedLength`
    const usageCounter = isOnlyShared ? validSharedLength : validSharedLength + 1;

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{
                ...style,
                width: 680,
                maxWidth: "90vw"
            }}>
                <Typography sx={{
                    fontWeight: 600,
                    fontSize: 24,
                    mb: 2,
                    textTransform: "uppercase"
                }}>
                {confirm ? t("Your session detailed") : t("Book a session")}
                </Typography>
                <ScrollContainer hideScrollbars={false} className="scroll-container max-h-[80vh] py-2">
                    <form onSubmit={handleBookSession}>
                    {
                        session?.is_free_session == 1 ? (
                            <Box>
                                <Typography>
                                    {t("You can register for this session without having to sign up for any membership plans")}
                                </Typography>
                                <LoadingButton
                                    loading={isLoading}
                                    variant='contained'
                                    type='submit'
                                    disabled={usageCounter <= 0}
                                    sx={{
                                        background: "#000",
                                        padding: "10px 12px",
                                        flex: 1,
                                        borderRadius: 0,
                                        display: "block",
                                        mt: 2,
                                        mb: 2,
                                        textAlign: 'center',
                                        letterSpacing: 2
                                    }}>
                                    {t("Book session")}
                                </LoadingButton>
                            </Box>
                        ): !selectedSubscription ? (
                        <Box>
                            {
                                Array.isArray(filterSubscription) && filterSubscription?.length ? (
                                    <Fragment>
                                        <InputLabel id="demo-simple-select-label">
                                            {t("Select subscription")}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            name="subscription_id"
                                            value={selectedSubscription?.id}
                                            onChange={(e) => handleChangeSubscription(e)}
                                            sx={{
                                                mb: 2,
                                                width: "100%"
                                            }}>
                                            {
                                                filterSubscription.map((val) => {
                                                    return (
                                                        <MenuItem
                                                            key={val.id}
                                                            sx={{ fontSize: 14 }}
                                                            value={val.id}>
                                                            <Box>
                                                                <Typography sx={{ fontWeight: 600 }}>
                                                                    {val.membership.title} { val.membership?.can_be_share ? <span className='font-normal text-sm'>({t('Can be shared')})</span> : ""}
                                                                </Typography>
                                                                <Typography sx={{ fontSize: 12 }}>
                                                                    {val.max_sessions == -1 ? t("unlimited")  : `${val.max_sessions - val.class_session_user_count} / ${val.max_sessions}`}
                                                                </Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                        {formError?.subscription_id ? <FormHelperText>{formError?.subscription_id[0]}</FormHelperText> : ""}
                                    </Fragment>
                                ): (
                                    <p className='text-center'>{t("Sorry, you don't have any subscription that is compatible with this session. Please go to the")} <Link className='underline' to="/memberships">{t("membership page")}</Link>.</p>
                                )
                            }
                            
                        </Box>
                        ) : (
                            <FormControl fullWidth error={formError?.subscription_id}>
                                {
                                    !personalSelect && selectedSubscription?.membership?.can_be_share ? (
                                        <div className="grid grid-cols-1 gap-3">
                                            <div className="border p-3 border-black">
                                                <h3 className='font-bold text-xl'>
                                                    {t("Personal Booking and Shared with Others")}
                                                </h3>
                                                <p className='text-[14px]'>
                                                    {t("Choose this option if you are booking for yourself and others. You will receive the check-in QR code via email, which you can use for yourself and forward to others.")}
                                                </p>
                                                <Button 
                                                    variant='outlined' 
                                                    sx={{borderRadius: 0, mt: 2, fontWeight: 600, borderColor: "#000", color: "#000"}}
                                                    onClick={() => handleSelectPersonalBook(false)}>
                                                        {t("Next")}
                                                </Button>
                                            </div>
                                            <div className="border p-3 border-black">
                                                <h3 className='font-bold text-xl'>
                                                    {t("Booking for someone else")}
                                                </h3>
                                                <p className='text-[14px]'>
                                                    {t("Select this option if you are booking on behalf of someone else and will not be attending. The check-in QR code will be sent to your email to share with the person attending.")}
                                                </p>
                                                <Button 
                                                    variant='outlined' 
                                                    sx={{borderRadius: 0, mt: 2, fontWeight: 600, borderColor: "#000", color: "#000"}}
                                                    onClick={() => handleSelectPersonalBook(true)}>
                                                        {t("Next")}
                                                </Button>
                                            </div>
                                            <Button 
                                                variant='plain' 
                                                sx={{ borderRadius: 0}} 
                                                onClick={() => setSelectedSubscription(false)}>
                                                {t("Back to select subscription")}
                                            </Button>
                                        </div>
                                    ) : (
                                        <Box>
                                        {
                                            !confirm && selectedSubscription?.membership?.can_be_share ? (
                                                <Fragment>
                                            {
                                                selectedSubscription?.membership?.can_be_share ? (
                                                    <Box>
                                                        <h3 className='font-bold'>{t("Add shared person")}</h3>
                                                        <p className='text-sm'>{t("Add the people you want to register for this class. For each person shared, your class credit will be deducted.")}</p>
                                                        <Box>
                                                            {
                                                                Array.isArray(shared) && shared.length ? 
                                                                    shared.map((val, index) => {
                                                                        return (
                                                                            <Stack direction="row" spacing={1} sx={{my: 2,}} key={index}>
                                                                                <TextField 
                                                                                    label={t("Your shared name") + " (" + (index + 1) + ")"}
                                                                                    sx={{flex: 1}} 
                                                                                    value={val} 
                                                                                    onChange={(e) => handleChangeShared(e.target.value, index)}
                                                                                />
                                                                                <IconButton 
                                                                                    variant='outlined' 
                                                                                    sx={{ borderColor: "#000", borderRadius: 0 }} 
                                                                                    onClick={() => handleDeleteShared(index)}>
                                                                                        <DeleteOutlineIcon />
                                                                                </IconButton>
                                                                            </Stack>
                                                                        )
                                                                    }) : ""
                                                            }
                                                        </Box>
                                                        <div className="text-end">
                                                                <Button 
                                                                    variant='outlined'
                                                                    sx={{ borderColor: "#000", borderRadius: 0, mt: 1 }} 
                                                                    startIcon={<AddIcon />}
                                                                    disabled={disabledSharedButton || disabledSharedButton}
                                                                    onClick={() => handleAddShared()}>
                                                                    {t("Add shared")}
                                                                </Button>
                                                        </div>
                                                        <Stack direction="row" spacing={1} sx={{mt: 3}}>
                                                            <Button 
                                                                onClick={() => setPersonalSelect(false)} 
                                                                sx={{
                                                                    flex: 1,
                                                                    padding: "10px 12px",
                                                                    borderRadius: 0,
                                                                    display: "block",
                                                                    mt: 2,
                                                                    mb: 2,
                                                                    textAlign: 'center',
                                                                    letterSpacing: 2
                                                                }}
                                                                variant='outlined'>
                                                                {t("Back")}
                                                            </Button>
                                                            <Button
                                                                variant='contained'
                                                                type='button'
                                                                onClick={() => { setConfirm(true) }}
                                                                disabled={usageCounter <= 0}
                                                                sx={{
                                                                    background: "#000",
                                                                    padding: "10px 12px",
                                                                    width: "100%",
                                                                    borderRadius: 0,
                                                                    display: "block",
                                                                    mt: 2,
                                                                    mb: 2,
                                                                    flex: 1,
                                                                    textAlign: 'center',
                                                                    letterSpacing: 2
                                                                }}>
                                                                {t("Next step")}
                                                            </Button>
                                                        </Stack>
                                                    </Box>
                                                ) :  
                                                    selectedSubscription?.id && !confirm ? (
                                                        <Stack direction="row" spacing={1}>
                                                            <Button 
                                                                onClick={() => setSelectedSubscription(false)} 
                                                                sx={{
                                                                    flex: 1,
                                                                    padding: "10px 12px",
                                                                    borderRadius: 0,
                                                                    display: "block",
                                                                    mt: 2,
                                                                    mb: 2,
                                                                    textAlign: 'center',
                                                                    letterSpacing: 2
                                                                }}
                                                                variant='outlined'>
                                                                {t("Back")}
                                                            </Button>
                                                            <Button
                                                                variant='contained'
                                                                type='button'
                                                                onClick={() => { setConfirm(true) }}
                                                                disabled={usageCounter <= 0}
                                                                sx={{
                                                                    background: "#000",
                                                                    padding: "10px 12px",
                                                                    width: "100%",
                                                                    borderRadius: 0,
                                                                    display: "block",
                                                                    mt: 2,
                                                                    mb: 2,
                                                                    flex: 1,
                                                                    textAlign: 'center',
                                                                    letterSpacing: 2
                                                                }}>
                                                                {t("Next step")}
                                                            </Button>
                                                        </Stack>
                                                    ) : ""
                                                }
                                        </Fragment>
                                            ): (
                                                <Box sx={{ mb: 3}}>
                                                <div className="border-black mb-3">
                                                {
                                                    selectedClassType ? (
                                                        <Fragment>
                                                            <Typography>
                                                                <span className='text-2xl font-bold uppercase'>{selectedClassType?.title}</span>
                                                            </Typography>
                                                            <Typography className="text-sm" sx={{mb: 3}}>
                                                                {t(selectedClassType?.description)}
                                                            </Typography>
                                                        </Fragment>
                                                    ) : (
                                                        AcademicClass ?
                                                            <Fragment>
                                                                <Typography>
                                                                    <span className='text-2xl font-bold upe'>{AcademicClass?.title}</span>
                                                                </Typography>
                                                                <Typography className="text-sm" sx={{mb: 3}}>
                                                                    {AcademicClass?.description}
                                                                </Typography>
                                                            </Fragment>
                                                            : ""
                                                    )
                                                }
                                                {
                                                    start ?
                                                        <Fragment>
                                                            <Typography>
                                                                {t("on")} <span className='font-bold uppercase'> {start.format('ddd, DD/MM')}</span>
                                                            </Typography>
                                                            <Typography>
                                                                {t("at")} <span className='font-bold uppercase'>{start.format('HH:mm')}</span>
                                                            </Typography>
                                                        </Fragment>
                                                        : ""
                                                }
                                                {
                                                    durationInMinutes ?
                                                        <Typography>
                                                            {t("Duration")}: <span className="font-bold">{durationInMinutes} {t("minutes")}</span>
                                                        </Typography>
                                                        : ""
                                                }
                                                <Typography>
                                                    {t("Instructor")}: <strong>{trainer?.full_name || "-"}</strong> 
                                                </Typography>
                                                <Typography>{t("Number of reservations")}: <strong className='ml-2'>{ usageCounter }</strong></Typography>
                                                </div>
                                                {
                                                    Array.isArray(shared) && shared.length ? 
                                                        <Box sx={{ mt: 2, maxHeight: 360, overflowY: "auto" }}>
                                                            <h3 className='font-bold mb-0'>{t("Shared with person")}</h3>
                                                            {
                                                                shared.filter((val) => val != '').map((val, index) => {
                                                                    return (
                                                                        <Stack direction="row" spacing={1} key={index}>
                                                                            <Typography>{(index + 1)}. <strong>{val}</strong></Typography>
                                                                        </Stack>
                                                                    )
                                                                })
                                                            }
                                                        </Box> : ""
                                                }
                                                <Stack direction="row" spacing={1} sx={{mt: 3, mb: 2}}>
                                                    {
                                                        selectedSubscription?.membership?.can_be_share ? (
                                                            <Button 
                                                                onClick={() => setConfirm(false)} 
                                                                sx={{
                                                                    flex: 1,
                                                                    padding: "10px 12px",
                                                                    borderRadius: 0,
                                                                    display: "block",
                                                                    mt: 2,
                                                                    mb: 2,
                                                                    textAlign: 'center',
                                                                    letterSpacing: 2
                                                                }}
                                                                variant='outlined'>
                                                                {t("Back")}
                                                            </Button>
                                                        ) : (
                                                            <Button 
                                                                onClick={() => setSelectedSubscription(false)} 
                                                                sx={{
                                                                    flex: 1,
                                                                    padding: "10px 12px",
                                                                    borderRadius: 0,
                                                                    display: "block",
                                                                    mt: 2,
                                                                    mb: 2,
                                                                    textAlign: 'center',
                                                                    letterSpacing: 2
                                                                }}
                                                                variant='outlined'>
                                                                {t("Back")}
                                                            </Button>
                                                        )
                                                        
                                                    }
                                                    <LoadingButton
                                                        loading={isLoading}
                                                        variant='contained'
                                                        type='submit'
                                                        disabled={usageCounter <= 0}
                                                        sx={{
                                                            background: "#000",
                                                            padding: "10px 12px",
                                                            flex: 1,
                                                            borderRadius: 0,
                                                            display: "block",
                                                            mt: 2,
                                                            mb: 2,
                                                            textAlign: 'center',
                                                            letterSpacing: 2
                                                        }}>
                                                        {t("Book session")}
                                                    </LoadingButton>
                                                </Stack>
                                                <p className="text-sm">
                                                    <span className="text-red-600 mr-2">*</span>
                                                    {t("We will send the appointment booking information to the email address")} <strong>{user?.email}</strong>. {t("You will receive an email containing the appointment details and an attached event file. Please open the attachment and add it to your calendar.")}
                                                </p>
                                            </Box>
                                            )
                                        }
                                        </Box>
                                    )
                                }
                            </FormControl>
                        ) 
                    }
                </form>
                </ScrollContainer>
            </Box>
        </Modal>
  )
}

export default ModalWaitingList